@mixin navlinks-solid {

  .navigation:hover {
    color: steelblue;
  }

  .navigation-active {
    color: steelblue !important;
    border-bottom: 3px solid steelblue;
  }
}

@mixin navlinks-transparent {
  .navigation:hover {
    color: lightsteelblue;
  }
  .navigation-active {
    color: lightsteelblue !important;
    border-bottom: 3px solid lightsteelblue;
  }
}

@mixin navigation-common {
  .navigation {
    font-size: 16pt;
  }
  .navigation-active {
    font-size: 16pt;
  }
}


@mixin navigation-ease-animation {
  -webkit-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}

@mixin navbar-solid {
  background-color: white !important;
  @include navigation-common;
  @include navigation-ease-animation;
  @include navlinks-solid;

  .navigation {
    color: black;

    .navbar-light button.navbar-toggler .navbar-toggler-icon {
      color: red !important;
    }
  }

  img.logo-white {
    display: none;
  }

  img.logo-black {
    display: inline-block;
  }

  div.extra-info a {
    color: black;

    &:hover {
      color: steelblue;
    }
  }
}

@mixin navbar-transparent {
  background-color: rgba(0, 0, 0, 0.0) !important;

  @include navigation-common;
  @include navigation-ease-animation;
  @include navlinks-transparent;

  .navigation {
    color: white;
  }

  img.logo-white {
    display: inline-block;
  }

  img.logo-black {
    display: none;
  }

  .navbar-toggler-icon {
    color: red !important;
  }

  div.extra-info a {
    color: white;
  }
}

@media (min-width: 992px) {
  #navbar {
    &.navbar-transparent {
      @include navbar-transparent;
    }

    &.navbar-solid {
      @include navbar-solid;
    }
  }
}

#navbar {
  @include navbar-solid;
}

@mixin navbar-collapsed-styling {
  justify-content: space-between;
  div.extra-info {
    display: flex;
    flex-direction: row;
  }

  div.extra-info a {
    margin-left: 20px;
    margin-right: 20px;
  }
}


.navbar-collapse {
  &.collapse {
    @include navbar-collapsed-styling;
  }

  &.collapsing {
    @include navbar-collapsed-styling
  }
}

div.nav-item {
  display: flex;
  justify-content: center;
}




