@mixin footer {
  background-color: #1e1e1e;
  color: darkgrey;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}

@mixin footer-link {
  color: slategrey;

  &:hover {
    color: #a6a6a6;
  }
}

.page-footer img {
  margin-bottom: 20px;
}

.page-footer {
  @include footer;

  .footer-link {
    @include footer-link;
  }

}
