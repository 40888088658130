body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem!important;
  background-color: #232323 !important;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

@media (max-width: 992px) and (min-width: 360px) {
  .leaflet-container {
    height: 350px;
  }

  body {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.8rem;
    }

    h3 {
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 360px) {
  .leaflet-container {
    height: 230px;
  }
  body {
    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    h3 {
      font-size: 1.4rem;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

html {
  scroll-behavior: smooth;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
}

.App {
  text-align: center;
  min-height: 100%;
}

.kontakt_logo {
  img {
    margin-bottom: 10px;
    width: 200px;
  }
}



