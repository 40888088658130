@mixin link {
  color: lightsteelblue;

  &:hover {
    color: lightgrey;
  }
}

@mixin containerStyle {
  overflow-wrap: break-word;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  // set alignItems to stretch for banner look
  align-items: center
}

@mixin landing-page-inner-container {
  background-color: rgba(0, 0, 0, 0.6);
  margin: auto;
}

@mixin inner-container {
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;
  height: 100vh;
  width: 100vw;
  text-align: left
}

.page {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: white;

  a {
    @include link
  }

}

.warum-wir-page{
  background-color: white;
}

.datenschutz-page, .impressum-page,  {
  .inner-container {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.outer-container {
  @include containerStyle;
}

@media (min-width: 993px) {
  .inner-container {
    @include inner-container;
    padding: 100px 50px;
  }

  .landing-inner-container {
    @include landing-page-inner-container;
    padding: 50px;
  }
}

@media (max-width: 992px) {
  .inner-container {
    @include inner-container;
    padding: 60px 20px 20px;
  }

  .landing-inner-container {
    @include landing-page-inner-container;
    padding: 20px;
  }

  .custom-animation {
    width: inherit;
    height: inherit;
  }
}
